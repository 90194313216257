import { Injectable } from '@angular/core';
import { ApiAccountingService } from '../api/api-accounting.service';
import { IFeatureFlagProfile, FeatureFlagProfile } from '../models/featureFlagProfile';

@Injectable({
  providedIn: 'root'
})
export class FeatureProfileService {
    private ongoingRequest: Promise<FeatureFlagProfile> | null = null;
    private featureFlagProfile: FeatureFlagProfile | null = null;

    constructor(private readonly apiAccountingService: ApiAccountingService) {}

    public async loadFeatureFlagProfile(): Promise<FeatureFlagProfile> {
        if (this.featureFlagProfile) return this.featureFlagProfile;
        if (this.ongoingRequest) return await this.ongoingRequest;
    
        this.ongoingRequest = this.apiAccountingService.getFeatureProfile()
          .then(featureFlagProfile => (this.featureFlagProfile = new FeatureFlagProfile(featureFlagProfile.featureFlags)))
          .finally(() => {
            this.ongoingRequest = null;
            return this.featureFlagProfile;
          });
    
        return await this.ongoingRequest;
      }

      private MOCK_FEATURE_FLAG_PROFILE: IFeatureFlagProfile = {
        featureFlags: [
          { id: 1, featureName: "Dashboard_Activate_No_Tiles", description: "Activate no specific tiles", isEnabled: false },
          { id: 2, featureName: "Dashboard_Activate_Tiles", description: "Activate the tiles Steps, Cycling time and Exercise", isEnabled: true },
          { id: 3, featureName: "Dashboard_Audio", description: "Audio", isEnabled: false },
          { id: 4, featureName: "Dashboard_Blood_Value_Measurements", description: "Blood value measurements", isEnabled: false },
          { id: 5, featureName: "Dashboard_Custom_Measurements", description: "Custom measurements", isEnabled: false },
          { id: 6, featureName: "Dashboard_Excercise_Minutes", description: "Exercise minutes", isEnabled: false },
          { id: 7, featureName: "Dashboard_Images", description: "Images", isEnabled: true },
          { id: 8, featureName: "Dashboard_Lifestyle_Measurements", description: "Lifestyle measurements", isEnabled: true },
          { id: 9, featureName: "Dashboard_Medical_Measurements", description: "Medical measurements", isEnabled: false },
          { id: 10, featureName: "Dashboard_Mental_Measurements", description: "Mental health measurements", isEnabled: false },
          { id: 11, featureName: "Dashboard_Menu_Item", description: "Menu item (always on)", isEnabled: false },
          { id: 12, featureName: "Dashboard_Notes", description: "Notes", isEnabled: false },
          { id: 13, featureName: "Dashboard_Physical_Measurements", description: "Physical measurements", isEnabled: false },
          { id: 14, featureName: "Games_Menu_Item", description: "Menu item (always on) - Challenge and Rewards only if purchased by customer", isEnabled: false },
          { id: 15, featureName: "History_Menu_Item", description: "Menu item (always on) - Medical files only if PHR is selected", isEnabled: false },
          { id: 16, featureName: "Knowledge_Base_Menu_Item", description: "Menu item", isEnabled: false },
          { id: 17, featureName: "Logout_Menu_Item", description: "Menu item (always on)", isEnabled: false },
          { id: 18, featureName: "Measurements_Menu_Item", description: "Menu item", isEnabled: false },
          { id: 19, featureName: "Medical_Menu_Item", description: "Menu item", isEnabled: false },
          { id: 20, featureName: "Network_Menu_Item", description: "Menu Item (always on) - Communities only if purchased by customer", isEnabled: false },
          { id: 21, featureName: "PHR_Menu_Item", description: "Menu item", isEnabled: true },
          { id: 22, featureName: "Profile_Allergies", description: "Allergies", isEnabled: false },
          { id: 23, featureName: "Profile_General_Information", description: "General information (always on)", isEnabled: false },
          { id: 24, featureName: "Profile_Healthcare_Provider", description: "Healthcare provider", isEnabled: false },
          { id: 25, featureName: "Profile_Medical_Background", description: "Medical background", isEnabled: false },
          { id: 26, featureName: "Profile_Menu_Item", description: "Menu item (always on)", isEnabled: true },
          { id: 27, featureName: "Profile_Vaccinations", description: "Vaccinations", isEnabled: false },
          { id: 28, featureName: "Settings_Menu_Item", description: "Menu item (always on) - Research and Health only if purchased by customer", isEnabled: false }
      ]
    }
}