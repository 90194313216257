import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from '../menu.service';

import { FeatureProfileService } from '../../../../shared/utils/feature-profile-service';
import { FeatureFlagProfile } from '../../../../shared/models/featureFlagProfile';

@Component({
  selector: 'nav-bottom-menu',
  templateUrl: './nav-bottom-menu.component.html',
  styleUrls: [ './nav-bottom-menu.component.scss' ],
})
export class NavBottomMenuComponent implements OnInit {

  constructor(
    readonly router: Router,
    private featureFlagProfileService :FeatureProfileService
  ) {
  }

  // Icons that have iconActive are not styled using CSS and have a completely different SVG depending on active or not
  // Icons without iconActive are styled using CSS depending on whether they're active (orange color) or not (grey color).
  public MENU_ITEMS: MenuItem[] = [
    {
      id: 'DASHBOARD',
      icon: 'icn_dashboard_not_active.svg',
      iconActive: 'icn_dashboard_active.svg',
      routerLink: '/dashboard/home',
    },
    {
      id: 'NETWORK',
      icon: 'icn_network.svg',
      iconActive: 'icn_network_active.svg',
      routerLink: '/dashboard/network',
    },
    {
      id: 'GAMES',
      icon: 'icn_games.svg',
      routerLink: '/dashboard/games',
    },
    {
      id: 'MEDICAL',
      icon: 'icn_medical.svg',
      routerLink: '/dashboard/medical/medication',
    },
    {
      id: 'PHR',
      icon: 'icn_phr.svg',
      iconActive: 'icn_phr_active.svg',
      routerLink: '/dashboard/phr',
    },
  ];

  async ngOnInit(): Promise<void> {
    this.featureFlagProfileService.loadFeatureFlagProfile().then(featureFlagProfile => {
      this.applyFeatureFlagProfile(featureFlagProfile);
   });
  }

  applyFeatureFlagProfile(featureFlagProfile: FeatureFlagProfile) {
    this.MENU_ITEMS = this.MENU_ITEMS.filter(menuItem => 
      !featureFlagProfile.hasFeatureFlagDisabled(menuItem.id.toUpperCase() + '_MENU_ITEM')
    );
  }
}