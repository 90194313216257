import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BUTTON_STYLES } from '../../../components/button/button.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../api/api.service';
import { DateStringFormat } from '../../../components/date-time-picker/date-time-picker.component';
import SavedNote, {NoteType} from '../../../models/note/SavedNote';
import NoteFormData from '../../../models/note/NoteFormData';
import { EmojiListComponent } from '../../../components/emoji-list/emoji-list.component';
import {PremiumService} from "../../../utils/premiumService";

export interface EditedNoteItem {
  id: string;
  date: Date;
  mood?: string;
  note: string;
}

@Component({
  selector: 'add-note-panel',
  templateUrl: './add-note-panel.component.html',
  styleUrls: ['./add-note-panel.component.scss'],
})
export class AddNotePanelComponent implements OnInit, OnChanges {
  @ViewChild('emojiListComponent') emojiListComponent: EmojiListComponent;
  // field used when editing an text note record
  @Input() editedItemId: string;

  @Output() textNoteSaved = new EventEmitter();
  @Output() textNoteModalClosed = new EventEmitter();

  readonly DateStringFormat = DateStringFormat;
  readonly BUTTON_STYLES = BUTTON_STYLES;
  public addNoteForm: UntypedFormGroup;
  public formData: NoteFormData;
  editedItem: SavedNote;
  modalId = 'addNoteModal';
  isFormValid = false;

  now = new Date();

  constructor(
    public modal: NgxSmartModalService,
    public premiumService: PremiumService,
    private formBuilder: UntypedFormBuilder,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.addNoteForm = this.formBuilder.group({
      date: [new Date(), Validators.required],
      mood: [''],
      note: ['', Validators.required],
    });

    this.addNoteForm.valueChanges.subscribe(() => {
      // console.log('this.addNoteForm.value on subscribe hit', this.addNoteForm.value);
      this.isFormValid = this.addNoteForm.valid;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('add note panel ngOnChanges', changes);
    if (changes.editedItemId?.currentValue && this.modal.get(this.modalId).isVisible()) {
      this.loadNoteData();
    }
  }

  async loadNoteData(): Promise<void> {
    this.editedItem = await this.api.getNoteByNoteId(this.editedItemId);
    this.addNoteForm.get('date').setValue(new Date(this.editedItem.dateTime), {emitEvent: false});
    this.addNoteForm.get('note').setValue(this.editedItem.content, {emitEvent: false});
    this.addNoteForm.get('mood').setValue(this.editedItem.mood, {emitEvent: false});

    if (this.addNoteForm.get('mood') && this.addNoteForm.get('mood').value) {
      setTimeout(() => {
        // make sure that if a mood is selected, that its in the viewport
        this.scrollSelectedEmojiIntoView();
      });
    }
  }

  public async saveNote(): Promise<void> {
    if(!this.premiumService.userHasPremiumAccess(true)) {
      return ;
    }

    this.formData = {
      date: this.addNoteForm.get('date').value.toISOString(),
      mood: this.addNoteForm.get('mood').value,
      content: this.addNoteForm.get('note').value,
      type: NoteType.TEXT,
    };
    if (this.editedItemId) {
      // PUT API: editing existing note
      const editNoteResult = await this.api.editNote(this.formData, this.editedItemId);
    } else {
      // POST API: creating existing note
      const createNoteResult = await this.api.createNote(this.formData);
    }
    this.modal.close(this.modalId);
    this.textNoteSaved.emit();
  }

  resetValues(): void {
    this.addNoteForm.reset({
      date: new Date(),
      mood: '',
      note: '',
    });

    this.isFormValid = false;
    this.textNoteModalClosed.emit();
  }

  scrollSelectedEmojiIntoView(): void {
    const selectedMoodHtmlEl = this.emojiListComponent.emojiListRef.nativeElement.querySelector('.tile-wrapper.selected');
    if (selectedMoodHtmlEl) selectedMoodHtmlEl.scrollIntoView({ inline: 'center' });
  }
}
