import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { IFeatureFlagProfile } from '../models/featureFlagProfile';

@Injectable({
  providedIn: 'root'
})
export class ApiAccountingService {

  private readonly baseUrl = environment.apiAccountingBaseUrl;

  constructor(
    private httpAccountingClient: HttpClient
  ) {
  }

  async getFeatureProfile(): Promise<IFeatureFlagProfile | null> {
      return await this.httpAccountingClient.get<IFeatureFlagProfile>(`${this.baseUrl}feature-flags`).toPromise();
  }
}