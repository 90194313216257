import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  @Input() minTime = 1000;
  @Output() onLongPress = new EventEmitter<MouseEvent | TouchEvent>();

  private pressing = false;
  private timeoutId: any;

  constructor() { }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onPressStart(event: MouseEvent | TouchEvent): void {
    this.pressing = true;

    this.timeoutId = setTimeout(() => {
      if (this.pressing) {
        this.onLongPress.emit(event);
      }
    }, this.minTime);
  }
  
  @HostListener('mouseup')
  @HostListener('mouseleave')
  @HostListener('touchend')
  @HostListener('touchcancel')
  onPressEnd(): void {
    this.pressing = false;
    clearTimeout(this.timeoutId);
  }
}
