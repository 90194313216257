import { FeatureFlag } from './featureFlag';

export interface IFeatureFlagProfile {
    featureFlags: FeatureFlag[];
}

export class FeatureFlagProfile {
    featureFlags: FeatureFlag[];

    constructor(featureFlags: FeatureFlag[]) {
        // ensure featureNames all uppercase
        featureFlags.forEach(featureFlag => {
            featureFlag.featureName = featureFlag.featureName.toString().toUpperCase();
        });

        this.featureFlags = featureFlags;
    }

    public getFeatureFlags(): FeatureFlag[] | null {
        return this?.featureFlags;
    }

    public getFeatureFlag(featureName: string): FeatureFlag | null {
        return this.featureFlags.find(f => f.featureName === featureName.toUpperCase()) ?? null;
    }

    public getFeatureFlagStatus(featureName: string): boolean | null {
        return this.getFeatureFlag(featureName)?.isEnabled ?? null;
    }

    public hasFeatureFlagDisabled(featureName: string): boolean {
        return this.getFeatureFlagStatus(featureName) === false;
    }

    public hasFeatureFlagEnabled(featureName: string): boolean {
        return this.getFeatureFlagStatus(featureName) === true;
    }

    public hasFeatureFlagDefined(featureName: string): boolean {
        return this.getFeatureFlag(featureName) !== null;
    }
}